.footer {
    margin-top: 50px;
    background-color: #1a1a1a;
    color: #dcdcdc;
    padding: 30px 20px;
    position: relative;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-section {
    flex: 1;
    min-width: 200px;
    margin: 10px 20px;
}

.footer-section h3 {
    color: #fff;
    margin-bottom: 15px;
    font-size: 18px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-weight: 200;
}

.footer-section ul li a:hover {
    text-decoration: none;
}

.footer-about {
    color: #dcdcdc;
    margin: 10px 20px;
    font-size: 14px;
    line-height: 1.6;
}

.contact-info {
    text-align: center;
    margin: 20px 0;
    font-size: 14px;
}

.footer p {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }
    .footer-section {
        margin: 10px 0;
        text-align: center;
    }
    .newsletter input[type="email"] {
        width: 90%;
        margin-bottom: 10px;
    }
    .social-icons {
        flex-wrap: wrap;
    }
    .social-icons a {
        margin: 5px;
    }
}

.social-media-float{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 35%;
    background-color: rgba(0,0,0,0.5);
    padding: 5px;
    padding-right: 20px;
    right: -15px;
    border-radius: 10px;
    z-index: 1000 !important;
}

.social-media-float i{
    font-size: 20px;
}

.social-media-float a{
    display: flex;
    flex-direction: column;
    font-size: 7.5px;
    margin: 5px 2.5px;
}
