body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "san serif";
    font-size: 16px;
    letter-spacing: 1px;
}

@media (min-width: 576px) {
    body{
        font-size: 12px;
    }
}

.navbar div{
    border: solid 1px red;
}
/* Navbar */
.navbar {
    padding:0% 5%;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 10%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    background-color: rgba(254, 255, 255);
    /* color: white; */
    /* background-color: rgb(29, 197, 197); */
    top: 0;
    position: fixed;
    z-index: 250;
}

.navbar .navbar-title {
    display: flex;
    flex-direction: row;
}

.navbar .navbar-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center; 
    font-size: 18px;
    cursor: pointer;
    padding: 0px;
}

.navbar .navbar-list div:hover {
    transition: all ease-in-out 0.2s;
    color: rgb(196, 128, 2);
}

.navbar .navbar-title h1 {
    margin: 5px;
    font-size: 300%;
    font-weight: bold;
}

.navbar .navbar-title img {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 10%;
    height: 100%;
    border-radius: 5px;
}

.active {
    color: rgb(196, 128, 2)
}

/* DropDown Tewting*/
.dropbtn {
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    top:6%;
    left:75%;
    display: none;
    position: fixed;
    background-color: #ffffff;
    min-width: 10%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    text-align: left;
}

.dropdown-content a {
    color: black;
    padding: 5% 20%;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content-comm {
    top:6%;
    left:65%;
    display: none;
    position: fixed;
    background-color: #ffffff;
    min-width: 10%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    text-align: left;
}

.dropdown-content-comm a {
    color: black;
    padding: 5% 20%;
    text-decoration: none;
    display: block;
}

.dropdown-content-comm a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content-comm {
    display: block;
}

.dropdown:hover .dropbtn {
    color: rgb(196, 128, 2)
}

/* DropDown */
/* Image Gallery */
.image-gallery {
    margin-top: 80px;
}

@media (max-width: 990px){
    .image-gallery {
    margin-top: 60px;
}
}

.image-gallery video {
    width: 100%;
}

/* Who we are */
.project-counter {
    /* display: flex;
    flex-direction: column; */
    padding: 5% 15%;
}

.project-counter .whoweare h6 {
    padding: 3% 0px;
    color: rgb(185, 122, 4);
    font-weight: bold;
    letter-spacing: 5px;
}

.project-counter .whoweare h1 {
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: justify;
}

.project-counter .counter {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
}

.project-counter .counter .project-des .project-count {
    color: rgb(196, 128, 2);
    font-weight: 300;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 500%;
    justify-content: center;
}

.project-counter .counter .project-describe {
    font-weight: bold;
}

@media (max-width: 990px){
    .project-counter {
        padding:0% 5%;
        padding-top: 10%;
        display: flex !important;
        flex-direction: column-reverse;
    }
    
    .project-counter .whoweare h6 {
        padding: 3% 0px;
        color: rgb(196, 128, 2);
        letter-spacing: 5px;
    }
    
    .project-counter .whoweare h1 {
        padding: 10px 0px;
        font-size: 100%;
        font-weight: 400;
        letter-spacing: 1px;
        text-align: justify;
    }
    
    .project-counter .counter {
        padding: 5%;
        font-size: 150%;
        display: flex;
        flex-direction: row;
    }
    
    .project-counter .counter .project-des .project-count {
        color: rgb(196, 128, 2);
        font-weight: 300;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 40px;
        justify-content: center;
    }
    
    .project-counter .counter .project-describe {
        font-weight: bold;
    }
}

/* About US */

.about-us {
    padding: 0% 15%;
}

.about-us img {
    width: 100%;
    border-radius: 25px;
}

.about-us .about-us-des {
    margin: 5%;
}

.about-us .about-us-des .about-us-title {
    border: solid rgb(255, 166, 0);
    padding: 2% 5%;
    text-align: center;
    border-radius: 100px;
    font-size: 18px;
    font-weight: bold;
    width: fit-content;
}

.about-us .about-us-des {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-us .about-us-des .about-us-link {
    color: rgb(151, 99, 1);
    text-decoration: underline;
    padding: 2% 5%;
}

.about-us .about-us-des .about-us-detail {
    margin: 5%;
    text-align: justify;
}

@media (max-width: 990px){
    .about-us {
        margin: 0%;
        padding:0%;
        display: flex !important;
        flex-direction: column !important;
    }
    
    .about-us img {
        width: 80%;
        border-radius: 25px;
        margin: 10%;
    }
    
    .about-us .about-us-des {
        margin: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .about-us .about-us-des .about-us-title {
        border: solid rgb(255, 166, 0);
        text-align: center;
        border-radius: 100px;
        font-size: 18px;
        font-weight: bold;
        width: fit-content;
        margin-top: 2.5%;
    }
    
    .about-us .about-us-des {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .about-us .about-us-des .about-us-link {
        color: rgb(151, 99, 1);
        text-decoration: underline;
        padding: 0%;
        float: right;
    }
    
    .about-us .about-us-des .about-us-detail {
        margin: 2.5%;
        text-align: justify;
    }
}

/* Featured Projects */
.project-feature {
    /* display: flex;
    flex-direction: column; */
    padding: 0% 15%;
}

.project-feature .point-title{
    padding: 3% 0px;
    color: rgb(196, 128, 2);
    letter-spacing: 5px;
    font-weight: bold;
}

.project-feature .point-desc h1 {
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: justify;
}

.project .point-desc p:hover {
    font-weight: bold;
}

.project-imgs {
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    /* padding: 1rem; */
}

.project-feature .grid {
    display: grid;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: start;
    justify-items: center;
}

.project-img {
    width: 250px !important;
    height: 350px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.project-img-over {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.project-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-img image:hover {
    transform: scale(110%);
    transition: all ease-in-out 0.2s;
}

.image {
    display: block;
    width: 100%;
    height: auto;
}

.project-img-over {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease-in-out;
    background-color: rgba(0, 0, 0, 0.5);
}

/* .project-img:hover .project-img-over {
    opacity: 1;
} */

.project-img .project-img-over {
    opacity: 1;
}

.project-img-over h1 {
    /* text-transform: uppercase; */
    color: rgb(219, 179, 1);
    font-size: 25px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    padding: 2.5% 10%;
}

.project-img-over p {
    text-transform: capitalize;
    color: rgb(255, 255, 255);
    padding: 5% 10%;
    font-size: 18px;
}

.project-img:hover img {
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
}

.project-img:hover .project-img-over {
    background-color: rgba(0, 0, 0, 0.1);
}

.project-img:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.project-feature .point-desc p {
    color: rgb(151, 99, 1);
    text-decoration: underline;
    text-align: right;
    cursor: pointer;
}

@media (max-width: 990px){
    .project-feature {
        padding:0% 5%;
    }
    
    .project-feature .point-title h6 {
        padding: 3% 0px;
        color: rgb(196, 128, 2);
        letter-spacing: 5px;
    }
    
    .project-feature .point-desc h1 {
        padding: 10px 0px;
        font-size: 100%;
        font-weight: 400;
        letter-spacing: 1px;
        text-align: justify;
    }
    
    .project-feature .point-desc p {
        color: rgb(151, 99, 1);
        text-decoration: underline;
        text-align: right;
        cursor: pointer;
    }
    
    .project .point-desc p:hover {
        font-weight: bold;
    }
    
    .project-imgs {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
    }
    
    .project-img {
        width: 150px !important;
        height: 200px;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        margin: 5px;
    }
    
    .project-img-over {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    
    .project-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .project-img image:hover {
        transform: scale(110%);
        transition: all ease-in-out 0.2s;
    }
    
    .image {
        display: block;
        width: 100%;
        height: auto;
    }
    
    .project-img-over {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease-in-out;
        background-color: rgba(0, 0, 0, 0.3);
    }
    
    /* .project-img:hover .project-img-over {
        opacity: 1;
    } */
    
    .project-img .project-img-over {
        opacity: 1;
    }
    
    .project-img-over h1 {
        /* text-transform: uppercase; */
        color: whitesmoke;
        font-size: 20px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: bold;
        padding: 5% 10%;
    }
    
    .project-img-over p {
        text-transform: capitalize;
        color: rgb(255, 255, 255);
        padding: 10% 10%;
    }
    
    .project-img:hover img {
        transform: scale(1.1);
        transition: all 0.5s ease-in-out;
    }
    
    .project-img:hover .project-img-over {
        background-color: rgba(0, 0, 0, 0.1);
    }
    
    .project-img:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    }
}

/* Footer */
.footer-class {
    /* background-color: rgb(255, 0, 0); */
    background: #000;
    color: #ffffff;
    overflow: hidden;
    margin-top: 5%;
}

.footer-column {
    margin: 10% 20%;
}

.footer-column h3 {
    text-transform: uppercase;
    font-size: 100%;
    letter-spacing: 2px;
    margin: 5% 0%;
    font-weight: 500;
}

.footer-column p {
    font-size: 90%;
}

.footer-bottom {
    display: flex;
    justify-content: center;
    margin-bottom: 2.5%;
}

.footer-column .social-icons {
    display: flex;
    flex-direction: column;
}

@media (max-width: 990px){
    .footer-class {
        /* background-color: rgb(255, 0, 0); */
        background: #000;
        color: #ffffff;
        overflow: hidden;
        margin-top: 5%;
    }

    .footer-content{
        display: flex !important;
        flex-direction: column;
    }
    
    .footer-column {
        margin:1% 5%  !important;
    }
    
    .footer-column h3 {
        text-transform: uppercase;
        font-size: 100%;
        letter-spacing: 2px;
        margin: 5% 0%;
        font-weight: 500;
    }
    
    .footer-column p {
        font-size: 90%;
    }

    .footer-column .social-icons{
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between;
    }
    
    .footer-bottom {
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 2.5%;
    }
    
    .footer-column .social-icons {
        display: flex;
        flex-direction: column;
    }

    .footer-about{
        display: none !important;
    }
}

.intro-video{
    margin: 3% 15%;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.intro-video iframe{
    width: 800px;
    height: 500px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
    padding: 10px;
    border-radius: 15px;
}

@media (max-width: 990px){
    .intro-video{
        margin: 2% 7.5%;
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .intro-video iframe{
        width: 500px;
        height: 250px;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
        padding: 10px;
        border-radius: 15px;
    }
}