body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "san serif";
    font-size: 16px;
    letter-spacing: 1px;
}

/* Title Project */
.project-status{
    margin: 2.5% 0%;
    padding: 0% 15%;
}

.project-status h1{
    font-size: 150%;
    border: solid rgb(223, 145, 1) 5px;
    padding:1% 2%;
    border-radius: 10rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

/* Project Card */
.comm-projects div, .comm-projects{
    /* border: solid red 1px; */
}

.comm-projects{
    margin: 2.5% 0%;
    padding: 0% 15%;
}

.comm-projects .grid{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.project-card{
    width: 250px !important;
    margin: 20px;
    height: 300px;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: none;
}

.project-card:hover{
    box-shadow: none;
}

.project-card .project-card-info{
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.3);
}

.project-card-info h1{
    font-weight: 400;
    color: white;
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 200%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.project-card-info p{
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 125%;
}

.projects{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 575px){
    .project-status{
        margin: 2.5% 0%;
        padding: 0% 5%;
    }
    
    .project-status h1{
        font-size: 100%;
        border: solid rgb(223, 145, 1) 5px;
        padding:1% 2%;
        border-radius: 10rem;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
    }
    
    /* Project Card */
    .comm-projects div, .comm-projects{
        /* border: solid red 1px; */
    }
    
    .comm-projects{
        margin: 2.5% 0%;
        padding: 0% 5%;
    }
    
    .comm-projects .grid{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .project-card{
        width: 45% !important;
        margin: 2.5%;
        height: 200px;
        cursor: pointer;
        border-radius: 20px;
        box-shadow: none;
    }
    
    .project-card:hover{
        box-shadow: none;
    }
    
    .project-card .project-card-info{
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.3);
    }
    
    .project-card-info h1{
        color: white;
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 100%;
    }
    
    .project-card-info p{
        padding: 0;
        margin: 0;
        font-weight: 300;
        font-size: 75%;
    }

    .projects{
        display: flex;
        flex-wrap: wrap;
    }
}

/* Footer */
.footer-class {
    /* background-color: rgb(255, 0, 0); */
}

.footer-column {
    margin: 10% 20%;
}

.footer-column h3 {
    text-transform: uppercase;
    font-size: 100%;
    letter-spacing: 2px;
    margin: 5% 0%;
    font-weight: 500;
}

.footer-column p {
    font-size: 90%;
}

.footer-bottom {
    display: flex;
    justify-content: center;
    margin-bottom: 2.5%;
}

.footer-column .social-icons {
    display: flex;
    flex-direction: column;
}