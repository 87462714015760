body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "san serif";
    font-size: 16px;
    letter-spacing: 1px;
}

/* Banner */
.building-banner{
    height: 350px;
}

.building-banner .banner-path h1{
    letter-spacing: 2.5px;
}

@media (max-width: 990px){
    .building-banner{
        margin-top: 60px;
        height: 300px;
    }

    .building-banner .banner-path{
        width: 80%;
    }

    .building-banner .banner-path p{
        font-size: 15px;
    }

    .about-banner{
        margin-top: 0% !important;
        padding-top: 0% !important;
    }
}

/* Sub Navbar */
.sub-navbar div, .sub-navbar, ul, li{
    /* border: solid red 1px; */
}

.sub-navbar{
    padding: 0% 15%;
}

.sub-navbar ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.sub-navbar ul li{
    font-size: 13.5px;
    padding: 1.5% 2.5%;
    font-weight: 300;
    color: white;
    background: #ffb937;
    border: solid white 1px;
    border-radius: 5px;
    width: 350px;
}

.sub-navbar ul li:hover{
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    background-color: #e9a21f;
    transition: all ease-in-out 0.25s;
}

@media (max-width: 990px){    
    .sub-navbar{
        padding:0% 5%;
    }
    
    .sub-navbar ul{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    
    .sub-navbar ul li{
        width: 100px;
        font-size: 10px;
        padding: 1.5% 2.5%;
        font-weight: 300;
        color: white;
        background: #ffc04b;
        border: solid white 1px;
        border-radius: 5px;
    }
}

/* Overview Navbar */
.overview-navbar div{
    /* border: solid red 1px; */
}

.overview-navbar{
    margin: 0%;
    padding: 0% 15%;
}

.overview-navbar .overview-title{
    font-size: 250%;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5%;
}

.overview-navbar .overview-detail .overview-image{
    width: 90%;
    border-radius: 10%;
}

.overview-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2.5%;
}

.overview-detail .overview-para{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: justify;
    font-size: 18px;
    font-weight: 400;
}

.overview-detail {
    padding-bottom: 5%;
}

.overview-detail .overview-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    padding-right: 15%;
}

.overview-detail .overview-desc div{
    display: flex;
    flex-direction: column;
}

.overview-desc div h1{
    padding:2% 0%;
    font-size: 100%;
    font-weight: 300;
}

.overview-desc div p{
    font-size: 140%;
    font-weight: bold;
}

@media (max-width: 990px){
    .overview-navbar{
        margin: 0%;
        padding: 0% 5%;
    }
    
    .overview-navbar .overview-title{
        font-size: 150%;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 5%;
        padding-top: 10%;
    }
    
    .overview-navbar .overview-detail .overview-image{
        width: 100%;
        border-radius: 5%;
    }
    
    .overview-image img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2.5%;
    }
    
    .overview-detail .overview-para{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: justify;
        font-size: 15px;
        font-weight: 400;
        padding: 5% 0%;
    }
    
    .overview-detail {
        padding-bottom: 2.5%;
        display: flex !important;
        flex-direction: column;
    }
    
    .overview-detail .overview-desc{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: right;
        padding-right: 0%;
    }
    
    .overview-detail .overview-desc div{
        display: flex;
        flex-direction: column;
    }
    
    .overview-desc div h1{
        padding:2% 0%;
        font-size: 100%;
        font-weight: 300;
    }
    
    .overview-desc div p{
        font-size: 140%;
        font-weight: bold;
    }
}

/* Amenities */
.amenities-navbar div, img, p{
    /* border: solid red 1px; */
}

.amenities-navbar{
    padding: 0% 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 7.5%;
}

.amenities-navbar .amenities-slogan{
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    color: #c47f01;
}

.amenities-navbar .amenities-title{
    margin: 10px;
    font-size: 250%;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.amenities-navbar .amenities-desc p{
    padding: 2.5% 7.5%;
    font-weight: 400;
    font-size: 18px;
    text-align: justify;
    text-transform: capitalize;
}

.amenities-navbar .amenities-provide{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    max-width: 900px;
    justify-content: space-evenly;
    padding: 0%;

}
.amenities-provide .amenity{
    overflow: hidden;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.amenities-provide .amenity img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 20px;
}

.amenities-provide .amenity p{
    margin: 5px;
    font-weight: 400;
}

.amenities-provide .amenity .amenity-switch-des{
    display: block;
}

.amenities-provide .amenity .amenity-switch-mob{
    display: none;
}

@media (max-width: 990px){
    .amenities-navbar{
        padding: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 10%;
    }
    
    .amenities-navbar .amenities-slogan{
        font-weight: bold;
        font-size: 15px;
        letter-spacing: 2px;
        color: #c47f01;
    }
    
    .amenities-navbar .amenities-title{
        font-size: 25px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .amenities-navbar .amenities-desc p{
        padding: 2.5%;
        font-weight: 400;
        text-transform: capitalize;
        font-size: 15px;
        text-align: justify;
    }
    
    .amenities-navbar .amenities-provide{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: space-evenly;
        padding: 5% 1%;
    
    }
    .amenities-provide .amenity{
        overflow: hidden;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        margin: 10px;
    }
    
    .amenities-provide .amenity img{
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 20px;
    }
    
    .amenities-provide .amenity p{
        margin: 5px;
        font-size: 10px;
        font-weight: 400;
    }

    .amenities-provide .amenity .amenity-switch-des{
        display: none;
    }

    .amenities-provide .amenity .amenity-switch-mob{
        display: block;
    }
}

/* Floor Plan */
.floor-navbar div{
    /* border: solid red 1px; */
}

.floor-navbar{
    padding: 0% 7.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 5%;
}

.floor-navbar .floor-slogan{
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 2px;
    color: #c47f01;
    padding-top: 1%;
}

.floor-navbar .floor-title{
    font-size: 250%;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.floor-navbar .floor-desc p{
    padding: 2.5% 15%;
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
}

.floor-navbar .floor-imgs{
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 500px;
}

.floor-imgs .floor-plan{
    width: 45%;
    margin:0% 1%;
    /* border: solid rgb(8, 23, 236) 1px; */
}

.floor-imgs .floor-design{
    width: 45%;
    margin:0% 1%;
    border: solid rgb(121, 121, 4) 1px;
}

@media (max-width: 990px){
    .floor-navbar{
        padding: 0% 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        padding-top: 5%;
    }
    
    .floor-navbar .floor-slogan{
        font-weight: bold;
        font-size: 100%;
        letter-spacing: 2px;
        color: #c47f01;
        padding-top: 1%;
    }
    
    .floor-navbar .floor-title{
        font-size: 150%;
        padding: 5%;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .floor-navbar .floor-desc p{
        padding: 2.5% 5%;
        font-size: 15px;
        font-weight: 400;
        text-transform: capitalize;
    }
    
    .floor-navbar .floor-imgs{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 500px;
    }
    
    .floor-imgs .floor-plan{
        width: 100%;
        margin:0% 1%;
        /* border: solid rgb(8, 23, 236) 1px; */
    }

    .floor-plan h1{
        text-align: center;
    }
    
    .floor-imgs .floor-design{
        width: 100%;
        margin:0% 1%;
        border: solid rgb(121, 121, 4) 1px;
    }
}

/* Location Map */
.map-location div{
    /* border: solid red 1px; */
}

.map-location{
    padding: 0% 5%;
}

.map-location .location-title{
    font-size: 250%;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1%;
}

.map-location .map-class{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.map-location .map-class iframe{
    width: 100%;
    height: 450px;
    max-height: 600px;
}

@media (max-width: 990px){
    .map-location{
        padding: 0% 5%;
        padding-top: 15% !important;
    }
    
    .map-location .location-title{
        font-size: 150%;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 5%;
    }
    
    .map-location .map-class iframe{
        width: 100%;
        height: 250px;
    }
}

/* Available */
.available-unit{
    padding: 0% 15%;
}

.available-unit .available-title{
    font-size: 250%;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5%;
}

table{
    border: solid black 1px;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

th{
    background-color: rgb(255, 176, 101);
}

th, td{
    padding: 1%;
}

td button{
    background-color: rgb(255, 176, 101);
    padding: 2.5%;
    border-radius: 5px;
}

@media (max-width: 990px){
    .available-unit{
        padding: 0% 5%;
        padding-top: 5%;
    }
    
    .available-unit .available-title{
        font-size: 150%;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 5%;
    }
    
    table{
        border: solid black 1px;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 50%;
    }
    
    th{
        background-color: rgb(255, 176, 101);
    }
    
    th, td{
        padding: 1%;
    }
    
    td button{
        background-color: rgb(255, 176, 101);
        padding: 2.5%;
        border-radius: 5px;
    }

    td button:hover{
        background-color: rgb(255, 143, 37);
        transition: all ease-in-out 3ms;
        box-shadow: 0px 4px 8px rgba(0,0,0,0.3);
    }
}

/* Enquiry */
.enquiry-form{
    padding: 0% 15%;
}

.enquiry-form .enquiry-title{
    font-size: 250%;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2.5%;
}

@media (max-width: 990px){
    .enquiry-form{
        padding: 0% 5%;
        display: flex;
        flex-direction: column;
    }

    .enquiry-form .enquiry-title{
        font-size: 250%;
        font-weight: 400 !important;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 2.5%;
    }
}

/* Contact Form */
/* Hide Contact */
.hide-contact{
    display: none;
}

.thanks img{
    width: 25%;
    margin: 10%;
}

.thanks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hide-all{
    display: none !important;
}

.hide-all h1, .hide-all h5, .hide-all img{
    display: none;
}

.get-all h1, .get-all h5, .get-all img{
    display: block;
}

/* Alert Box */
.alert-form{
    display: block;
    color: red;
}

.alert-hide{
    display: none;
}

.hide-timestamp{
    display: none;
}

.hide-loading{
    display: none;
}

.get-loading{
    display: block;
}

.hide-loading{
    display: none !important;
}

.hide-loading span{
    display: none !important;
}

.get-loading span{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.contact-area{
    /* width: 50%;
    height: 500px; */
    /* max-height: 450px; */
}

.height-100{
    height: 100%;
}

.loading{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 2%;
    padding: 25% 0%;
}

.form-ele{
    padding: 1% 0%;
    display: flex;
    flex-direction: column;
}

.form-ele label{
    color: black;
    padding-bottom: 5px;
    font-weight: bold;
}

.form-ele input, select{
    height: 40px;
    border: solid gainsboro 1px;
    border-radius: 5px;
    outline: none;
}

.form-ele textarea{
    border: solid gainsboro 1px;
    border-radius: 5px;
    outline: none;
}

.form-ele button{
    padding: 2.5%;
    border-radius: 5px;
    background-color: rgb(255, 176, 101);
    border: none;
}

.form-ele button:hover{
    background-color: rgb(248, 155, 68);
    transition: all ease-in-out 0.3s;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
}

@media (max-width: 990px){
    .enquiry-form{
        padding: 0% 5%;
    }
    
    .enquiry-form .enquiry-title{
        font-size: 150%;
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 5%;
    }
}

/* Footer */
.footer-class {
    /* background-color: rgb(255, 0, 0); */
}

.footer-column {
    margin: 10% 20%;
}

.footer-column h3 {
    text-transform: uppercase;
    font-size: 100%;
    letter-spacing: 2px;
    margin: 5% 0%;
    font-weight: 500;
}

.footer-column p {
    font-size: 90%;
}

.footer-bottom {
    display: flex;
    justify-content: center;
    margin-bottom: 2.5%;
}

.footer-column .social-icons {
    display: flex;
    flex-direction: column;
}