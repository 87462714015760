body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "san serif";
    font-size: 16px;
    letter-spacing: 1px;
}

/* Banner */
.about-banner, .about-banner div{
    /* border: solid red 1px; */
}

.about-banner{
    overflow: hidden;
    position: relative;
    text-align: center;
    color: black;
    margin-top: 80px;
    width: 100%;
    height: 300px;
}

.about-banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 100%;
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.about-banner .banner-path{
    padding: 2.5% 5%;
    background-color: rgba(255,255,255,0.5);
    border-radius: 50px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.about-banner .banner-path h1{
    font-weight: bold;
    font-size: 250%;
    letter-spacing: 5px;
    padding: 10px;
}

.about-banner .banner-path p a{
    text-decoration: underline;
    cursor: pointer;
}

.about-us, .about-us div{
    /* border: solid red 1px; */
}

@media (max-width: 990px) {
    .about-banner{
        overflow: hidden;
        position: relative;
        text-align: center;
        color: black;
        width: 100%;
        height: 250px;
        margin-top: 60px !important;
    }
    
    .about-banner img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 100% 100%;
        filter: blur(3px);
        -webkit-filter: blur(3px);
    }
    
    .about-banner .banner-path{
        padding: 2.5% 5%;
        background-color: rgba(255,255,255,0.5);
        border-radius: 20px;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
        position: absolute;
        margin-top: 25px;
        transform: translate(-50%, -50%);
    }
    
    .about-banner .banner-path h1{
        font-weight: bold;
        font-size: 150%;
        letter-spacing: 5px;
        padding: 5px;
    }
    
    .about-banner .banner-path p a{
        text-decoration: underline;
        cursor: pointer;
    }
    
    .about-us{
        margin: 100px 0px;
    }
    
    .about-us, .about-us div{
        /* border: solid red 1px; */
    }
}

.about-us{
    margin: 100px 0px;
}

/* About us */
.aboutUsImageDiv{
    width: 100%;
    height: 100%;
    display: flex;
}

.aboutUsImageDiv img{
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
}

@media (max-width: 990px) {
    .about-us{
        margin: 0px;
        padding: 0px !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .aboutUsImageDiv{
        width: 83.5%;
        height: 90%;
    }
    
    .aboutUsImageDiv img{
        width: 100%;
        object-fit: cover;
        border-radius: 25px;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
    }
}

/* Vision Mission cards */
.vision-mission{
    margin: 100px 0px;
}

.vision-mission .vision-mission-image{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 500px;
    text-align: center;
    color: black;
}

.vision-mission-image img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    object-position: 100% 100%;
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.vision-mission-card{
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    padding: 0% 2.5%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vision-mission-card .vision-card{
    /* background-color: rgba(1, 29, 80, 0.8); */
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    padding: 15px;
    border-radius: 20px;
    margin: 2.5%;
    box-shadow: 0px 5px 10px rgba(255,255,255,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vision-card img{
    filter: blur(0px);
    -webkit-filter: blur(0px);
    width: 100px;
    height: 100px;
    border-radius: 15px;
    margin: 5%;
}

.vision-card h1{
    font-size: 200%;
    font-weight: 500;
}

.vision-card p{
    text-align: justify;
    padding: 5% 7.5%;
}

@media (max-width: 990px) {
    .vision-mission{
        margin: 25px 0px;
    }
        
    .vision-mission .vision-mission-image{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 800px;
        text-align: center;
        color: black;
    }
    
    .vision-mission-image img{
        width: 100%;
        object-fit: cover;
        height: 95%;
        object-position: 100% 100%;
        filter: blur(3px);
        -webkit-filter: blur(3px);
    }
    
    .vision-mission-card{
        display: flex !important;
        flex-direction: column !important;
        position: absolute;
        width: 100%;
        padding: 0%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0%;
    }
    
    .vision-mission-card .vision-card{
        background-color: rgba(255, 255, 255, 0.8);
        width: 90%;
        padding: 2.5%;
        border-radius: 20px;
        margin: 5%;
        box-shadow: 0px 5px 10px rgba(255,255,255,0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .vision-card img{
        filter: blur(0px);
        -webkit-filter: blur(0px);
        width: 50px;
        height: 50px;
        border-radius: 15px;
        margin: 2.5%;
    }
    
    .vision-card h1{
        font-size: 18px;
        font-weight: bold;
    }
    
    .vision-card p{
        text-align: justify;
        padding: 2.5%;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: normal;
    }
}

/* Board Directors */
.board-class, .board-class div{
    /* border: solid red 1px; */
}

.board-class{
    padding: 0% 15%;
}

.board-class .board-class-heading{
    font-size: 25px;
    font-weight: bold;
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.board-class .board-class-cards{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.board-class-cards .director-card{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
}

.director-card .director-image{
    width: 750px;
    height: 250px;
    border-radius: 15px;
    display: flex;
}

.director-image img{
    border-radius: 15px;
    width: 100%;
    object-fit: cover;
}

.director-card .director-info{
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: flex-start;
    text-align: justify;
}

.director-info h6{
    color: rgb(201, 154, 1); 
    letter-spacing: 2px;
    padding: 0px 0px; 
}

.director-info h1{
    padding: 5px 0px;
    letter-spacing: 2px;
    font-size: 200%;
    font-weight: 500;
}

.director-info p{
    font-weight: 300;
}

@media (max-width: 990px) {
    .board-class{
        padding: 1px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .board-class .board-class-heading{
        font-size: 25px;
        font-weight: bold;
        margin: 10px;;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .board-class .board-class-cards{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: center;
    }
    
    .board-class-cards .director-card{
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 15px;
        border-radius: 15px;
        box-shadow: 0px 5px 10px rgba(0,0,0,0);
    }
    
    .director-card .director-image{
        width: 300px;
        height: 350px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
    }
    
    .director-image img{
        border-radius: 15px;
        width: 100%;
        object-fit: cover;
    }
    
    .director-card .director-info{
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: center;
        align-items: center;
        text-align: left;
    }
    
    .director-info h6{
        color: rgb(199, 140, 12); 
        letter-spacing: 1px;
        font-weight: bold;
        padding: 0px 0px; 
    }
    
    .director-info h1{
        padding: 5px 0px;
        letter-spacing: 1px;
        font-size: 22spx;
        font-weight: 500;
    }
    
    .director-info p{
        font-weight: 300;
        text-align: justify;
    }
}

/* Footer */
.footer-class {
    /* background-color: rgb(255, 0, 0); */
}

.footer-column {
    margin: 10% 20%;
}

.footer-column h3 {
    text-transform: uppercase;
    font-size: 100%;
    letter-spacing: 2px;
    margin: 5% 0%;
    font-weight: 500;
}

.footer-column p {
    font-size: 90%;
}

.footer-bottom {
    display: flex;
    justify-content: center;
    margin-bottom: 2.5%;
}

.footer-column .social-icons {
    display: flex;
    flex-direction: column;
}

/* Contact CSS */
.div-1{
    margin: 2.5%;
    padding: 2.5%;
    background-color: rgb(255, 236, 218);
    border-radius: 10px;
}

.div-2{
    margin: 2.5%;
    border-radius: 10px;
}

.div-2 iframe{
    height: 100%;
    width: 100%;
}

.contact-us-detail{
    padding: 2.5% 10%;
    font-size: 125%;
    display: flex;
    font-weight: bold;
}

.contact-us-detail p{
    font-weight: 400;
}

.contact-us-detail-icons{
    padding: 2.5% 10%;
    font-size: 125%;
    display: flex;
    font-weight: bold;
    flex-direction: column;
}

.enquiry-form{
    margin-top: 5%;
}

@media (max-width: 990px){
    .enquiry-form{
        display: flex;
        flex-direction: column-reverse;
    }

    .div-1{
        margin: 2.5%;
        padding: 2.5%;
        background-color: rgb(255, 236, 218);
        border-radius: 10px;
    }
    
    .div-2{
        margin: 2.5%;
        border-radius: 10px;
        font-size: 80%;
    }
    
    .div-2 iframe{
        height: 100%;
        width: 100%;
    }
    
    .contact-us-detail{
        padding: 2.5% 10%;
        font-size: 125%;
        display: flex;
        font-weight: bold;
    }
    
    .contact-us-detail p{
        font-weight: 400;
    }
    
    .contact-us-detail-icons{
        padding: 2.5% 10%;
        font-size: 125%;
        display: flex;
        font-weight: bold;
        flex-direction: column;
    }
    
}
